/*
 * @Author: Liux- 1163777695@qq.com
 * @Date: 2024-05-10 11:28:12
 * @LastEditors: liux 1163777695@qq.com
 * @LastEditTime: 2025-03-10 15:36:21
 * @FilePath: /pc_mobel/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/jcAi',
    name: 'jcAi',
    component: () => import(/* webpackChunkName: "about" */ '../views/AiModel/jcAi.vue'),
    title: '机场AI'
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    title: '科创平台'
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "about" */ '../views/test/testIndex.vue')
  },
  {
    path: '/TalentCalculator',
    name: 'TalentCalculator',
    component: () => import(/* webpackChunkName: "about" */ '../views/TalentCalculator/TalenTIndex.vue'),
  },
  {
    path: '/Costofliving',
    name: 'Costofliving',
    component: () => import(/* webpackChunkName: "about" */ '../views/Costofliving/index.vue'),
  },
  {
    path: '/ComputeShcb',
    name: 'ComputeShcb',
    component: () => import(/* webpackChunkName: "about" */ '../views/Costofliving/ComputeShcb.vue'),
  },
  {
    path: '/QuickIndex',
    name: 'QuickIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/TalentCalculator/Quickmatch/QuickIndex'),
  },
  {
    path: '/look',
    name: 'look',
    component: () => import(/* webpackChunkName: "about" */ '../views/look/lookIndex.vue')
  },
  {
    path: '/detial',
    name: 'detial',
    component: () => import(/* webpackChunkName: "about" */ '../views/look/detialIndex.vue')
  },
  {
    path: '/calculationResult',
    name: 'calculationResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculationResult/calculationResult.vue')
  },
  {
    path: '/goZwfw',
    name: 'goZwfw',
    component: () => import(/* webpackChunkName: "about" */ '../views/goZwfw.vue')
  },
  {
    path: '/ai',
    name: 'ai',
    component: () => import(/* webpackChunkName: "about" */ '../views/AiModel/aiIndex.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
