/*
 * @Author: Liux- 1163777695@qq.com
 * @Date: 2024-05-10 11:28:12
 * @LastEditors: liux 1163777695@qq.com
 * @LastEditTime: 2025-03-07 10:12:33
 * @FilePath: /pc_mobel/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import  "amfe-flexible";
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import './assets/css/global.css'
import './assets/fonts/fonts.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus).use(store).use(router).mount('#app')



